import { OrdersStatsSummary } from "src/app/core/interfaces/dispatch-stats.interface";
import { Worker } from "src/app/core/interfaces/worker.interface";
import { QueueTimes } from "../../../core/enums/queue-times.enum";
import { LaborOrder } from "../../../core/interfaces/labor-order.interface";

export class DispatchByDateStateModel {
	loading: boolean;
	saving: boolean;
	
	sumarizeOrders: LaborOrder[];
	dispatchByDateStats: OrdersStatsSummary;

	searchDate: string;
	searchUnionId: number;
	allowBump: boolean;
	useWorkerDayCallResult: boolean;
  
  workersLoading: boolean;
	workersFilters: WorkerFiltersDBD;
	allWorkers: Worker[];
	totalWorkers: number;
}

export interface WorkerFiltersDBD {
	SearchQ?: string;
	AvailabilityTypeIds?: string[];
	jobTypes?: number[];
	queueType?: QueueTimes;
	onlyBumpeds?: boolean;
	
	skipCount?: number;
	maxCount?: number;
}