import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DisconnectCall, SwitchTrackAudio } from '../../states/calls/calls.actions';
import { CallInstance, CallStatus } from '../../states/calls/calls.model';
import { CallsState } from '../../states/calls/calls.state';

@Component({
  selector: 'app-call',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, AsyncPipe],
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent {

  @Select(state => state.calls.call) call$: Observable<CallInstance>;
  @Select(state => state.calls.status) status$: Observable<CallStatus>;
  micIcon: string;

  constructor(
    private store: Store
  ) {
    this.micIcon = 'mic';
  }
  
  ngAfterViewInit(): void {
    this.store.select(CallsState.isMuted).subscribe(
      (response) => {
        this.micIcon = response ? 'mic_off' : 'mic';
      }
    )
  }

  turnMicrophone(): void {
    this.store.dispatch(
      new SwitchTrackAudio()
    );
  }

  disconnect(): void {
    this.store.dispatch(
      new DisconnectCall()
    );
  }

}
