export class CreateDevice {
  public static readonly type = "[Calls] create device";
  constructor(
    public token: string
  ) {}
}

export class MakeOutgoingCall {
  public static readonly type = "[Calls] make outgoing call";
  constructor(
    public name: string,
    public phoneNumber: string,
    public unionId: number
  ) {}
}

export class SwitchTrackAudio {
  public static readonly type = "[Calls] switch track audio";
}

export class DisconnectCall {
  public static readonly type = "[Calls] disconnect call";
}

export class ClearCall {
  public static readonly type = "[Calls] clear call";
}