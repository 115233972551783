export interface CallsStateModel {
  call: CallInstance;
}

export interface CallInstance {
  receiver: {
    name: string;
    phoneNumber: string;
  }
  status: CallStatus;
  muted: boolean;
}

export enum CallStatus {
  Connecting = 'Connecting...',
  Ringring = 'Calling...',
  Canceled = 'Cancelled...',
  Completed = 'Completed...',
  Failed = 'Failed...',
  Busy = 'No answer...',
  NoAnswer = 'No answer...',
  Disconnected = 'Disconnected',
  Reconnecting = 'Reconnecting...'
}

// Twillio call status: queued, ringing, in-progress, canceled, completed, failed, busy or no-answer