"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @packageDocumentation
 * @module Voice
 */
var events_1 = require("events");
var device_1 = require("./device");
var errors_1 = require("./errors");
var log_1 = require("./log");
var outputdevicecollection_1 = require("./outputdevicecollection");
var mediadeviceinfo_1 = require("./shims/mediadeviceinfo");
var util_1 = require("./util");
/**
 * Aliases for audio kinds, used for labelling.
 * @private
 */
var kindAliases = {
  audioinput: 'Audio Input',
  audiooutput: 'Audio Output'
};
/**
 * Provides input and output audio-based functionality in one convenient class.
 * @publicapi
 */
var AudioHelper = /** @class */function (_super) {
  __extends(AudioHelper, _super);
  /**
   * @constructor
   * @private
   * @param onActiveOutputsChanged - A callback to be called when the user changes the active output devices.
   * @param onActiveInputChanged - A callback to be called when the user changes the active input device.
   * @param [options]
   */
  function AudioHelper(onActiveOutputsChanged, onActiveInputChanged, options) {
    var _a;
    var _this = _super.call(this) || this;
    /**
     * A Map of all audio input devices currently available to the browser by their device ID.
     */
    _this.availableInputDevices = new Map();
    /**
     * A Map of all audio output devices currently available to the browser by their device ID.
     */
    _this.availableOutputDevices = new Map();
    /**
     * The currently set audio constraints set by setAudioConstraints().
     */
    _this._audioConstraints = null;
    /**
     * The audio stream of the default device.
     * This is populated when _openDefaultDeviceWithConstraints is called,
     * See _selectedInputDeviceStream for differences.
     * TODO: Combine these two workflows (3.x?)
     */
    _this._defaultInputDeviceStream = null;
    /**
     * Whether each sound is enabled.
     */
    _this._enabledSounds = (_a = {}, _a[device_1.default.SoundName.Disconnect] = true, _a[device_1.default.SoundName.Incoming] = true, _a[device_1.default.SoundName.Outgoing] = true, _a);
    /**
     * The current input device.
     */
    _this._inputDevice = null;
    /**
     * Whether the {@link AudioHelper} is currently polling the input stream's volume.
     */
    _this._isPollingInputVolume = false;
    /**
     * An instance of Logger to use.
     */
    _this._log = new log_1.default('AudioHelper');
    /**
     * Internal reference to the processed stream
     */
    _this._processedStream = null;
    /**
     * The selected input stream coming from the microphone device.
     * This is populated when the setInputDevice is called, meaning,
     * the end user manually selected it, which is different than
     * the defaultInputDeviceStream.
     * TODO: Combine these two workflows (3.x?)
     */
    _this._selectedInputDeviceStream = null;
    /**
     * A record of unknown devices (Devices without labels)
     */
    _this._unknownDeviceIndexes = {
      audioinput: {},
      audiooutput: {}
    };
    /**
     * Update the available input and output devices
     * @private
     */
    _this._updateAvailableDevices = function () {
      if (!_this._mediaDevices || !_this._enumerateDevices) {
        return Promise.reject('Enumeration not supported');
      }
      return _this._enumerateDevices().then(function (devices) {
        _this._updateDevices(devices.filter(function (d) {
          return d.kind === 'audiooutput';
        }), _this.availableOutputDevices, _this._removeLostOutput);
        _this._updateDevices(devices.filter(function (d) {
          return d.kind === 'audioinput';
        }), _this.availableInputDevices, _this._removeLostInput);
        var defaultDevice = _this.availableOutputDevices.get('default') || Array.from(_this.availableOutputDevices.values())[0];
        [_this.speakerDevices, _this.ringtoneDevices].forEach(function (outputDevices) {
          if (!outputDevices.get().size && _this.availableOutputDevices.size && _this.isOutputSelectionSupported) {
            outputDevices.set(defaultDevice.deviceId).catch(function (reason) {
              _this._log.warn("Unable to set audio output devices. " + reason);
            });
          }
        });
      });
    };
    /**
     * Remove an input device from inputs
     * @param lostDevice
     * @returns Whether the device was active
     */
    _this._removeLostInput = function (lostDevice) {
      if (!_this.inputDevice || _this.inputDevice.deviceId !== lostDevice.deviceId) {
        return false;
      }
      _this._destroyProcessedStream();
      _this._replaceStream(null);
      _this._inputDevice = null;
      _this._maybeStopPollingVolume();
      var defaultDevice = _this.availableInputDevices.get('default') || Array.from(_this.availableInputDevices.values())[0];
      if (defaultDevice) {
        _this.setInputDevice(defaultDevice.deviceId);
      }
      return true;
    };
    /**
     * Remove an input device from outputs
     * @param lostDevice
     * @returns Whether the device was active
     */
    _this._removeLostOutput = function (lostDevice) {
      var wasSpeakerLost = _this.speakerDevices.delete(lostDevice);
      var wasRingtoneLost = _this.ringtoneDevices.delete(lostDevice);
      return wasSpeakerLost || wasRingtoneLost;
    };
    options = Object.assign({
      AudioContext: typeof AudioContext !== 'undefined' && AudioContext,
      setSinkId: typeof HTMLAudioElement !== 'undefined' && HTMLAudioElement.prototype.setSinkId
    }, options);
    _this._updateUserOptions(options);
    _this._audioProcessorEventObserver = options.audioProcessorEventObserver;
    _this._mediaDevices = options.mediaDevices || navigator.mediaDevices;
    _this._onActiveInputChanged = onActiveInputChanged;
    _this._enumerateDevices = typeof options.enumerateDevices === 'function' ? options.enumerateDevices : _this._mediaDevices && _this._mediaDevices.enumerateDevices.bind(_this._mediaDevices);
    var isAudioContextSupported = !!(options.AudioContext || options.audioContext);
    var isEnumerationSupported = !!_this._enumerateDevices;
    if (options.enabledSounds) {
      _this._enabledSounds = options.enabledSounds;
    }
    var isSetSinkSupported = typeof options.setSinkId === 'function';
    _this.isOutputSelectionSupported = isEnumerationSupported && isSetSinkSupported;
    _this.isVolumeSupported = isAudioContextSupported;
    if (_this.isVolumeSupported) {
      _this._audioContext = options.audioContext || options.AudioContext && new options.AudioContext();
      if (_this._audioContext) {
        _this._inputVolumeAnalyser = _this._audioContext.createAnalyser();
        _this._inputVolumeAnalyser.fftSize = 32;
        _this._inputVolumeAnalyser.smoothingTimeConstant = 0.3;
      }
    }
    _this.ringtoneDevices = new outputdevicecollection_1.default('ringtone', _this.availableOutputDevices, onActiveOutputsChanged, _this.isOutputSelectionSupported);
    _this.speakerDevices = new outputdevicecollection_1.default('speaker', _this.availableOutputDevices, onActiveOutputsChanged, _this.isOutputSelectionSupported);
    _this.addListener('newListener', function (eventName) {
      if (eventName === 'inputVolume') {
        _this._maybeStartPollingVolume();
      }
    });
    _this.addListener('removeListener', function (eventName) {
      if (eventName === 'inputVolume') {
        _this._maybeStopPollingVolume();
      }
    });
    _this.once('newListener', function () {
      // NOTE (rrowland): Ideally we would only check isEnumerationSupported here, but
      //   in at least one browser version (Tested in FF48) enumerateDevices actually
      //   returns bad data for the listed devices. Instead, we check for
      //   isOutputSelectionSupported to avoid these quirks that may negatively affect customers.
      if (!_this.isOutputSelectionSupported) {
        _this._log.warn('Warning: This browser does not support audio output selection.');
      }
      if (!_this.isVolumeSupported) {
        _this._log.warn("Warning: This browser does not support Twilio's volume indicator feature.");
      }
    });
    if (isEnumerationSupported) {
      _this._initializeEnumeration();
    }
    return _this;
  }
  Object.defineProperty(AudioHelper.prototype, "audioConstraints", {
    /**
     * The currently set audio constraints set by setAudioConstraints(). Starts as null.
     */
    get: function () {
      return this._audioConstraints;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(AudioHelper.prototype, "inputDevice", {
    /**
     * The active input device. Having no inputDevice specified by `setInputDevice()`
     * will disable input selection related functionality.
     */
    get: function () {
      return this._inputDevice;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(AudioHelper.prototype, "inputStream", {
    /**
     * The current input stream coming from the microphone device or
     * the processed audio stream if there is an {@link AudioProcessor}.
     */
    get: function () {
      return this._processedStream || this._selectedInputDeviceStream;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(AudioHelper.prototype, "processedStream", {
    /**
     * The processed stream if an {@link AudioProcessor} was previously added.
     */
    get: function () {
      return this._processedStream;
    },
    enumerable: false,
    configurable: true
  });
  /**
   * Destroy this AudioHelper instance
   * @private
   */
  AudioHelper.prototype._destroy = function () {
    this._stopDefaultInputDeviceStream();
    this._stopSelectedInputDeviceStream();
    this._destroyProcessedStream();
    this._maybeStopPollingVolume();
    this.removeAllListeners();
    this._unbind();
  };
  /**
   * Start polling volume if it's supported and there's an input stream to poll.
   * @private
   */
  AudioHelper.prototype._maybeStartPollingVolume = function () {
    var _this = this;
    if (!this.isVolumeSupported || !this.inputStream) {
      return;
    }
    this._updateVolumeSource();
    if (this._isPollingInputVolume || !this._inputVolumeAnalyser) {
      return;
    }
    var bufferLength = this._inputVolumeAnalyser.frequencyBinCount;
    var buffer = new Uint8Array(bufferLength);
    this._isPollingInputVolume = true;
    var emitVolume = function () {
      if (!_this._isPollingInputVolume) {
        return;
      }
      if (_this._inputVolumeAnalyser) {
        _this._inputVolumeAnalyser.getByteFrequencyData(buffer);
        var inputVolume = util_1.average(buffer);
        _this.emit('inputVolume', inputVolume / 255);
      }
      requestAnimationFrame(emitVolume);
    };
    requestAnimationFrame(emitVolume);
  };
  /**
   * Stop polling volume if it's currently polling and there are no listeners.
   * @private
   */
  AudioHelper.prototype._maybeStopPollingVolume = function () {
    if (!this.isVolumeSupported) {
      return;
    }
    if (!this._isPollingInputVolume || this.inputStream && this.listenerCount('inputVolume')) {
      return;
    }
    if (this._inputVolumeSource) {
      this._inputVolumeSource.disconnect();
      delete this._inputVolumeSource;
    }
    this._isPollingInputVolume = false;
  };
  /**
   * Call getUserMedia with specified constraints
   * @private
   */
  AudioHelper.prototype._openDefaultDeviceWithConstraints = function (constraints) {
    var _this = this;
    this._log.info('Opening default device with constraints', constraints);
    return this._getUserMedia(constraints).then(function (stream) {
      _this._log.info('Opened default device. Updating available devices.');
      // Ensures deviceId's and labels are populated after the gUM call
      // by calling enumerateDevices
      _this._updateAvailableDevices().catch(function (error) {
        // Ignore error, we don't want to break the call flow
        _this._log.warn('Unable to updateAvailableDevices after gUM call', error);
      });
      _this._defaultInputDeviceStream = stream;
      return _this._maybeCreateProcessedStream(stream);
    });
  };
  /**
   * Stop the default audio stream
   * @private
   */
  AudioHelper.prototype._stopDefaultInputDeviceStream = function () {
    if (this._defaultInputDeviceStream) {
      this._log.info('stopping default device stream');
      this._defaultInputDeviceStream.getTracks().forEach(function (track) {
        return track.stop();
      });
      this._defaultInputDeviceStream = null;
      this._destroyProcessedStream();
    }
  };
  /**
   * Unbind the listeners from mediaDevices.
   * @private
   */
  AudioHelper.prototype._unbind = function () {
    if (!this._mediaDevices || !this._enumerateDevices) {
      throw new errors_1.NotSupportedError('Enumeration is not supported');
    }
    if (this._mediaDevices.removeEventListener) {
      this._mediaDevices.removeEventListener('devicechange', this._updateAvailableDevices);
    }
  };
  /**
   * Update AudioHelper options that can be changed by the user
   * @private
   */
  AudioHelper.prototype._updateUserOptions = function (options) {
    if (typeof options.enumerateDevices === 'function') {
      this._enumerateDevices = options.enumerateDevices;
    }
    if (typeof options.getUserMedia === 'function') {
      this._getUserMedia = options.getUserMedia;
    }
  };
  /**
   * Adds an {@link AudioProcessor} object. Once added, the AudioHelper will route
   * the input audio stream through the processor before sending the audio
   * stream to Twilio. Only one AudioProcessor can be added at this time.
   *
   * See the {@link AudioProcessor} interface for an example.
   *
   * @param processor The AudioProcessor to add.
   * @returns
   */
  AudioHelper.prototype.addProcessor = function (processor) {
    this._log.debug('.addProcessor');
    if (this._processor) {
      throw new errors_1.NotSupportedError('Adding multiple AudioProcessors is not supported at this time.');
    }
    if (typeof processor !== 'object' || processor === null) {
      throw new errors_1.InvalidArgumentError('Missing AudioProcessor argument.');
    }
    if (typeof processor.createProcessedStream !== 'function') {
      throw new errors_1.InvalidArgumentError('Missing createProcessedStream() method.');
    }
    if (typeof processor.destroyProcessedStream !== 'function') {
      throw new errors_1.InvalidArgumentError('Missing destroyProcessedStream() method.');
    }
    this._processor = processor;
    this._audioProcessorEventObserver.emit('add');
    return this._restartStreams();
  };
  /**
   * Enable or disable the disconnect sound.
   * @param doEnable Passing `true` will enable the sound and `false` will disable the sound.
   * Not passing this parameter will not alter the enable-status of the sound.
   * @returns The enable-status of the sound.
   */
  AudioHelper.prototype.disconnect = function (doEnable) {
    this._log.debug('.disconnect', doEnable);
    return this._maybeEnableSound(device_1.default.SoundName.Disconnect, doEnable);
  };
  /**
   * Enable or disable the incoming sound.
   * @param doEnable Passing `true` will enable the sound and `false` will disable the sound.
   * Not passing this parameter will not alter the enable-status of the sound.
   * @returns The enable-status of the sound.
   */
  AudioHelper.prototype.incoming = function (doEnable) {
    this._log.debug('.incoming', doEnable);
    return this._maybeEnableSound(device_1.default.SoundName.Incoming, doEnable);
  };
  /**
   * Enable or disable the outgoing sound.
   * @param doEnable Passing `true` will enable the sound and `false` will disable the sound.
   * Not passing this parameter will not alter the enable-status of the sound.
   * @returns The enable-status of the sound.
   */
  AudioHelper.prototype.outgoing = function (doEnable) {
    this._log.debug('.outgoing', doEnable);
    return this._maybeEnableSound(device_1.default.SoundName.Outgoing, doEnable);
  };
  /**
   * Removes an {@link AudioProcessor}. Once removed, the AudioHelper will start using
   * the audio stream from the selected input device for existing or future calls.
   *
   * @param processor The AudioProcessor to remove.
   * @returns
   */
  AudioHelper.prototype.removeProcessor = function (processor) {
    this._log.debug('.removeProcessor');
    if (typeof processor !== 'object' || processor === null) {
      throw new errors_1.InvalidArgumentError('Missing AudioProcessor argument.');
    }
    if (this._processor !== processor) {
      throw new errors_1.InvalidArgumentError('Cannot remove an AudioProcessor that has not been previously added.');
    }
    this._destroyProcessedStream();
    this._processor = null;
    this._audioProcessorEventObserver.emit('remove');
    return this._restartStreams();
  };
  /**
   * Set the MediaTrackConstraints to be applied on every getUserMedia call for new input
   * device audio. Any deviceId specified here will be ignored. Instead, device IDs should
   * be specified using {@link AudioHelper#setInputDevice}. The returned Promise resolves
   * when the media is successfully reacquired, or immediately if no input device is set.
   * @param audioConstraints - The MediaTrackConstraints to apply.
   */
  AudioHelper.prototype.setAudioConstraints = function (audioConstraints) {
    this._log.debug('.setAudioConstraints', audioConstraints);
    this._audioConstraints = Object.assign({}, audioConstraints);
    delete this._audioConstraints.deviceId;
    return this.inputDevice ? this._setInputDevice(this.inputDevice.deviceId, true) : Promise.resolve();
  };
  /**
   * Replace the current input device with a new device by ID.
   * @param deviceId - An ID of a device to replace the existing
   *   input device with.
   */
  AudioHelper.prototype.setInputDevice = function (deviceId) {
    this._log.debug('.setInputDevice', deviceId);
    return this._setInputDevice(deviceId, false);
  };
  /**
   * Unset the MediaTrackConstraints to be applied on every getUserMedia call for new input
   * device audio. The returned Promise resolves when the media is successfully reacquired,
   * or immediately if no input device is set.
   */
  AudioHelper.prototype.unsetAudioConstraints = function () {
    this._log.debug('.unsetAudioConstraints');
    this._audioConstraints = null;
    return this.inputDevice ? this._setInputDevice(this.inputDevice.deviceId, true) : Promise.resolve();
  };
  /**
   * Unset the input device, stopping the tracks. This should only be called when not in a connection, and
   *   will not allow removal of the input device during a live call.
   */
  AudioHelper.prototype.unsetInputDevice = function () {
    var _this = this;
    this._log.debug('.unsetInputDevice', this.inputDevice);
    if (!this.inputDevice) {
      return Promise.resolve();
    }
    this._destroyProcessedStream();
    return this._onActiveInputChanged(null).then(function () {
      _this._replaceStream(null);
      _this._inputDevice = null;
      _this._maybeStopPollingVolume();
    });
  };
  /**
   * Destroys processed stream and update references
   */
  AudioHelper.prototype._destroyProcessedStream = function () {
    if (this._processor && this._processedStream) {
      this._log.info('destroying processed stream');
      var processedStream = this._processedStream;
      this._processedStream.getTracks().forEach(function (track) {
        return track.stop();
      });
      this._processedStream = null;
      this._processor.destroyProcessedStream(processedStream);
      this._audioProcessorEventObserver.emit('destroy');
    }
  };
  /**
   * Get the index of an un-labeled Device.
   * @param mediaDeviceInfo
   * @returns The index of the passed MediaDeviceInfo
   */
  AudioHelper.prototype._getUnknownDeviceIndex = function (mediaDeviceInfo) {
    var id = mediaDeviceInfo.deviceId;
    var kind = mediaDeviceInfo.kind;
    var index = this._unknownDeviceIndexes[kind][id];
    if (!index) {
      index = Object.keys(this._unknownDeviceIndexes[kind]).length + 1;
      this._unknownDeviceIndexes[kind][id] = index;
    }
    return index;
  };
  /**
   * Initialize output device enumeration.
   */
  AudioHelper.prototype._initializeEnumeration = function () {
    var _this = this;
    if (!this._mediaDevices || !this._enumerateDevices) {
      throw new errors_1.NotSupportedError('Enumeration is not supported');
    }
    if (this._mediaDevices.addEventListener) {
      this._mediaDevices.addEventListener('devicechange', this._updateAvailableDevices);
    }
    this._updateAvailableDevices().then(function () {
      if (!_this.isOutputSelectionSupported) {
        return;
      }
      Promise.all([_this.speakerDevices.set('default'), _this.ringtoneDevices.set('default')]).catch(function (reason) {
        _this._log.warn("Warning: Unable to set audio output devices. " + reason);
      });
    });
  };
  /**
   * Route input stream to the processor if it exists
   */
  AudioHelper.prototype._maybeCreateProcessedStream = function (stream) {
    var _this = this;
    if (this._processor) {
      this._log.info('Creating processed stream');
      return this._processor.createProcessedStream(stream).then(function (processedStream) {
        _this._processedStream = processedStream;
        _this._audioProcessorEventObserver.emit('create');
        return _this._processedStream;
      });
    }
    return Promise.resolve(stream);
  };
  /**
   * Set whether the sound is enabled or not
   * @param soundName
   * @param doEnable
   * @returns Whether the sound is enabled or not
   */
  AudioHelper.prototype._maybeEnableSound = function (soundName, doEnable) {
    if (typeof doEnable !== 'undefined') {
      this._enabledSounds[soundName] = doEnable;
    }
    return this._enabledSounds[soundName];
  };
  /**
   * Stop the tracks on the current input stream before replacing it with the passed stream.
   * @param stream - The new stream
   */
  AudioHelper.prototype._replaceStream = function (stream) {
    this._log.info('Replacing with new stream.');
    if (this._selectedInputDeviceStream) {
      this._log.info('Old stream detected. Stopping tracks.');
      this._stopSelectedInputDeviceStream();
    }
    this._selectedInputDeviceStream = stream;
  };
  /**
   * Restart the active streams
   */
  AudioHelper.prototype._restartStreams = function () {
    if (this.inputDevice && this._selectedInputDeviceStream) {
      this._log.info('Restarting selected input device');
      return this._setInputDevice(this.inputDevice.deviceId, true);
    }
    if (this._defaultInputDeviceStream) {
      var defaultDevice = this.availableInputDevices.get('default') || Array.from(this.availableInputDevices.values())[0];
      this._log.info('Restarting default input device, now becoming selected.');
      return this._setInputDevice(defaultDevice.deviceId, true);
    }
    return Promise.resolve();
  };
  /**
   * Replace the current input device with a new device by ID.
   * @param deviceId - An ID of a device to replace the existing
   *   input device with.
   * @param forceGetUserMedia - If true, getUserMedia will be called even if
   *   the specified device is already active.
   */
  AudioHelper.prototype._setInputDevice = function (deviceId, forceGetUserMedia) {
    var _this = this;
    if (typeof deviceId !== 'string') {
      return Promise.reject(new errors_1.InvalidArgumentError('Must specify the device to set'));
    }
    var device = this.availableInputDevices.get(deviceId);
    if (!device) {
      return Promise.reject(new errors_1.InvalidArgumentError("Device not found: " + deviceId));
    }
    this._log.info('Setting input device. ID: ' + deviceId);
    if (this._inputDevice && this._inputDevice.deviceId === deviceId && this._selectedInputDeviceStream) {
      if (!forceGetUserMedia) {
        return Promise.resolve();
      }
      // If the currently active track is still in readyState `live`, gUM may return the same track
      // rather than returning a fresh track.
      this._log.info('Same track detected on setInputDevice, stopping old tracks.');
      this._stopSelectedInputDeviceStream();
    }
    // Release the default device in case it was created previously
    this._stopDefaultInputDeviceStream();
    var constraints = {
      audio: Object.assign({
        deviceId: {
          exact: deviceId
        }
      }, this.audioConstraints)
    };
    this._log.info('setInputDevice: getting new tracks.');
    return this._getUserMedia(constraints).then(function (originalStream) {
      _this._destroyProcessedStream();
      return _this._maybeCreateProcessedStream(originalStream).then(function (newStream) {
        _this._log.info('setInputDevice: invoking _onActiveInputChanged.');
        return _this._onActiveInputChanged(newStream).then(function () {
          _this._replaceStream(originalStream);
          _this._inputDevice = device;
          _this._maybeStartPollingVolume();
        });
      });
    });
  };
  /**
   * Stop the selected audio stream
   */
  AudioHelper.prototype._stopSelectedInputDeviceStream = function () {
    if (this._selectedInputDeviceStream) {
      this._log.info('Stopping selected device stream');
      this._selectedInputDeviceStream.getTracks().forEach(function (track) {
        return track.stop();
      });
    }
  };
  /**
   * Update a set of devices.
   * @param updatedDevices - An updated list of available Devices
   * @param availableDevices - The previous list of available Devices
   * @param removeLostDevice - The method to call if a previously available Device is
   *   no longer available.
   */
  AudioHelper.prototype._updateDevices = function (updatedDevices, availableDevices, removeLostDevice) {
    var _this = this;
    var updatedDeviceIds = updatedDevices.map(function (d) {
      return d.deviceId;
    });
    var knownDeviceIds = Array.from(availableDevices.values()).map(function (d) {
      return d.deviceId;
    });
    var lostActiveDevices = [];
    // Remove lost devices
    var lostDeviceIds = util_1.difference(knownDeviceIds, updatedDeviceIds);
    lostDeviceIds.forEach(function (lostDeviceId) {
      var lostDevice = availableDevices.get(lostDeviceId);
      if (lostDevice) {
        availableDevices.delete(lostDeviceId);
        if (removeLostDevice(lostDevice)) {
          lostActiveDevices.push(lostDevice);
        }
      }
    });
    // Add any new devices, or devices with updated labels
    var deviceChanged = false;
    updatedDevices.forEach(function (newDevice) {
      var existingDevice = availableDevices.get(newDevice.deviceId);
      var newMediaDeviceInfo = _this._wrapMediaDeviceInfo(newDevice);
      if (!existingDevice || existingDevice.label !== newMediaDeviceInfo.label) {
        availableDevices.set(newDevice.deviceId, newMediaDeviceInfo);
        deviceChanged = true;
      }
    });
    if (deviceChanged || lostDeviceIds.length) {
      // Force a new gUM in case the underlying tracks of the active stream have changed. One
      //   reason this might happen is when `default` is selected and set to a USB device,
      //   then that device is unplugged or plugged back in. We can't check for the 'ended'
      //   event or readyState because it is asynchronous and may take upwards of 5 seconds,
      //   in my testing. (rrowland)
      if (this.inputDevice !== null && this.inputDevice.deviceId === 'default') {
        this._log.warn("Calling getUserMedia after device change to ensure that the           tracks of the active device (default) have not gone stale.");
        this._setInputDevice(this.inputDevice.deviceId, true);
      }
      this._log.debug('#deviceChange', lostActiveDevices);
      this.emit('deviceChange', lostActiveDevices);
    }
  };
  /**
   * Disconnect the old input volume source, and create and connect a new one with the current
   * input stream.
   */
  AudioHelper.prototype._updateVolumeSource = function () {
    if (!this.inputStream || !this._audioContext || !this._inputVolumeAnalyser) {
      return;
    }
    if (this._inputVolumeSource) {
      this._inputVolumeSource.disconnect();
    }
    try {
      this._inputVolumeSource = this._audioContext.createMediaStreamSource(this.inputStream);
      this._inputVolumeSource.connect(this._inputVolumeAnalyser);
    } catch (ex) {
      this._log.warn('Unable to update volume source', ex);
      delete this._inputVolumeSource;
    }
  };
  /**
   * Convert a MediaDeviceInfo to a IMediaDeviceInfoShim.
   * @param mediaDeviceInfo - The info to convert
   * @returns The converted shim
   */
  AudioHelper.prototype._wrapMediaDeviceInfo = function (mediaDeviceInfo) {
    var options = {
      deviceId: mediaDeviceInfo.deviceId,
      groupId: mediaDeviceInfo.groupId,
      kind: mediaDeviceInfo.kind,
      label: mediaDeviceInfo.label
    };
    if (!options.label) {
      if (options.deviceId === 'default') {
        options.label = 'Default';
      } else {
        var index = this._getUnknownDeviceIndex(mediaDeviceInfo);
        options.label = "Unknown " + kindAliases[options.kind] + " Device " + index;
      }
    }
    return new mediadeviceinfo_1.default(options);
  };
  return AudioHelper;
}(events_1.EventEmitter);
(function (AudioHelper) {})(AudioHelper || (AudioHelper = {}));
exports.default = AudioHelper;
