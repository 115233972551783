import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { Call, Device } from "@twilio/voice-sdk";
import { tap } from 'rxjs/operators';
import { NotificationService } from "src/app/core/services/notification.service";
import { CallsService } from "../../services/calls.service";
import { ClearCall, DisconnectCall, MakeOutgoingCall, SwitchTrackAudio } from "./calls.actions";
import { CallStatus, CallsStateModel } from "./calls.model";

@Injectable()
@State<CallsStateModel>({
  name: "calls"
})
export class CallsState {
  
  private device: Device;
  private call: Call;
  private userStream: MediaStream;

  constructor(
    private store: Store,
    private callsService: CallsService,
    private notificationService: NotificationService
  ) {}

  @Selector() static call(state: CallsStateModel) { return state.call; }
  @Selector() static inCall(state: CallsStateModel) { return !!state.call; }
  @Selector() static isMuted(state: CallsStateModel) { return state.call.muted; }
  @Selector() static status(state: CallsStateModel) { return state.call?.status; }

  async createDevice(token: string, unionId: number) {
    this.device = new Device(token, {
      logLevel: 1,
      tokenRefreshMs: 20000,
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU]
    });
    
    this.device.on('tokenWillExpire', () => {
      return this.callsService.getToken(unionId).toPromise().then(
        (response) => this.device.updateToken(response.token)
      );
    });

    // this.device.on("registered", () => {
    //   console.log("Twilio.Device Ready!");
    // });

    this.device.on('error', (error) => {
      // https://www.twilio.com/es-mx/docs/voice/sdks/error-codes
      switch (error?.code) {
        case 53000:
          this.store.dispatch(new ClearCall());
          break;

        default:
          this.notificationService.logError(error);
          this.notificationService.showError('An error has ocurred');
          break;
      }
    })
  }

  @Action(MakeOutgoingCall)
  async makeOutgoingCall(ctx: StateContext<CallsStateModel>, action: MakeOutgoingCall) {
    let identity: string;

    navigator.mediaDevices?.getUserMedia({ video: false, audio: true }).then(async(stream) => {
      this.userStream = stream;
      if (stream.active) {
        if (!this.device) {
          await new Promise((resolve, reject) => {
            return this.callsService.getToken(action.unionId).pipe(
              tap(
                (response) => {
                  this.createDevice(response.token, action.unionId);
                  identity = response.identity;
                  resolve(null);
                },
                (error) => {
                  resolve(null);
                }
              )
            ).subscribe();
          });
        }
    
        if (this.device && !this.call) {
          ctx.patchState({
            call: {
              receiver: {
                name: action.name,
                phoneNumber: action.phoneNumber
              },
              status: CallStatus.Connecting,
              muted: false
            }
          });
      
          const connectOptions: Device.ConnectOptions = {
            params: {
              To: action.phoneNumber,
              callingDeviceIdentity: identity
            }
          }
          
          this.call = await this.device.connect(connectOptions);
    
          this.call.on('ringing', (call) => {
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                status: CallStatus.Ringring
              }
            })
          });
    
          this.call.on('cancel', (response) => {
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                status: CallStatus.Disconnected
              }
            })
          });
    
          this.call.on('disconnect', (response) => {
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                status: CallStatus.Disconnected
              }
            });
            ctx.dispatch(new DisconnectCall());
          });
    
          this.call.on('reconnecting', (error) => { 
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                status: CallStatus.Reconnecting
              }
            })
          });
    
          this.call.on('reconnected', (error) => { 
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                status: CallStatus.Ringring
              }
            })
          });
          
          this.call.on('reject', () => {
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                status: CallStatus.NoAnswer
              }
            })
          });
    
          this.call.on('mute', (isMuted, call) => {
            ctx.patchState({
              call: {
                ...ctx.getState().call,
                muted: isMuted
              }
            });
          });
    
          this.call.on('error', (error) => {
            this.notificationService.logError(error);
            this.notificationService.showError('An error has ocurred');
          });
    
          this.callsService.createOverlay();
        }
      }
    }).catch((err) => {
      console.error(`you got an error: ${err}`);
		});
  }

  @Action(SwitchTrackAudio)
  switchTrackAudio(ctx: StateContext<CallsStateModel>) {
    if (this.call) {
      const state: CallsStateModel = ctx.getState();
      const isMuted: boolean = state.call.muted;
      this.call.mute(!isMuted);
      this.userStream.getAudioTracks().forEach((track) => {
        track.enabled = isMuted;
      });
      ctx.patchState({
        call: {
          ...state.call,
          muted: !isMuted
        }
      });
    }
  }

  @Action(DisconnectCall)
  disconnectCall(ctx: StateContext<CallsStateModel>) {
    if (this.call) {
      this.call.removeAllListeners();
      this.call.disconnect();
      this.call = null;
    }
    if (this.device) {
      this.device.disconnectAll();
      this.device.destroy();
      this.device = null;
    }
    this.userStream.getAudioTracks().forEach((e) => {
      e.stop();
    });
    this.callsService.detachOverlay();
    ctx.patchState({ call: null });
  }

  @Action(ClearCall)
  clearCall(ctx: StateContext<CallsStateModel>) {
    const state: CallsStateModel = ctx.getState();
    if (state.call) {
      ctx.patchState({ call: null })
    }
  }
}
