<div cdkDrag class="w-96 px-4 py-3 rounded border-2 border-gray-400 flex items-center justify-between">

  <div class="flex items-center">
    <img src="./assets/avatar.svg" class="w-10 mr-4" />
    <div class="flex flex-col">
      <span class="text-lg">{{ (call$ | async).receiver?.name }}</span>
      <span class="text-base font-semibold">{{ (call$ | async).status }}</span>
    </div>
  </div>

  <div class="ml-4 flex">
    <div>
      <button (click)="turnMicrophone()" mat-mini-fab color="">
        <mat-icon>{{ micIcon }}</mat-icon>
      </button>
    </div>
    <div class="ml-2">
      <button (click)="disconnect()" mat-mini-fab color="warn">
        <mat-icon>phone_disabled</mat-icon>
      </button>
    </div>
  </div>

</div>