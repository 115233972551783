import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from 'src/environments/settings';
import { EnvironmentService } from '../../core/services/environment.service';
import { CallComponent } from '../components/call/call.component';
import { TwilioToken } from '../interfaces/twilio-token.interface';

@Injectable({
  providedIn: 'root'
})
export class CallsService {

  private apiUrl: string;
  private overlayRef: OverlayRef;

  constructor(
    private http: HttpClient,
    private overlay: Overlay,
    private ngZone: NgZone,
    private env: EnvironmentService,
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getToken(unionId: number): Observable<TwilioToken> {
    return this.http.get<TwilioToken>(`${this.apiUrl}/app/workerCall/${unionId}/token`);
  }

  createOverlay(): void {
    this.ngZone.run(() => {
      this.overlayRef = this.overlay.create({
        backdropClass: 'cdk-overlay-transparent-backdrop',
        panelClass: 'cdk-call',
        positionStrategy: this.overlay.position().global().bottom('35px').centerHorizontally()
      });
  
      this.overlayRef.attach(
        new ComponentPortal(CallComponent)
      );
    });
  }

  detachOverlay(): void {
    this.overlayRef.detach();
  }
}